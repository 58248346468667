<template>
    <div>
        <div class="head-portrait-header">
            <div class="head-portrait-header-left">
                <img class="head-portrait" :src="userInfo.headPic" alt="">
                <!-- <button class="head-portrait-btn">更换头像</button> -->
            </div>
            <div class="head-portrait-header-right">
                <div class="portrait-header-right-first">
                    <span class="my-bill-name">{{userInfo.nickName}}</span>
                    <span class="my-bill-phone">{{phone}}</span>
                </div>
                <div class="portrait-header-right-second">
                    <span class="my-bill-sex-name">性别</span>
                    <span v-if="userInfo.userSex == 0" class="my-bill-sex">男</span>
                    <span v-if="userInfo.userSex == 1" class="my-bill-sex">女</span>
                </div>
                <div class="portrait-header-right-third">
                    <span class="real-name">实名认证</span>
                    <span class="user-status">已通过</span>
                    <!-- <span class="">已通过</span> -->
                    <!-- <a class="de-authentication" href="">去认证</a> -->
                </div>
            </div>
        </div>
        <div >
            <div style="margin-top:20px">
                <span class="search-text">账单类型：</span>
                <el-select @change="toggleStatus" v-model="value" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                <!-- <input class="search" type="text" placeholder="输入关键词" v-model="queryMsg">
                <button class="search-btn" @click="queryMember"><img  src="../../assets/home/search-icon.png" alt=""></button> -->
            </div>
            <div>
                <el-table
                    :header-cell-style="{textAlign:'center'}"
                    :cell-style="{textAlign:'center'}"
                    :data="tableData"
                    height="600"
                    style="width: 100%">
                    <el-table-column
                        prop="orderName"
                        label="房源">
                    </el-table-column>
                    <el-table-column
                        prop="orderType"
                        label="账单类型">
                    </el-table-column>
                    <el-table-column
                        prop="needPayPrice"
                        label="账单金额(元)">
                    </el-table-column>
                    <el-table-column
                        prop="orderStart"
                        label="收付款日期">
                    </el-table-column>
                    <el-table-column
                        prop="gmtPeriod"
                        label="账单周期">
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        label="状态">
                        <template slot-scope="scope">
                            <span  class="normal">{{ getStatusText(scope.row.status)}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pagination-container">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="numContent">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { parse } from 'path'
import request from '../../api/index'
export default {
    name:"MyBill",
    data(){
        return{
            options:[],
            userInfo:'',
            tableData:[],
            numContent:0,
            pageRow:10,
            pageNum:1,
            phone:'',
            options: [{
                value: 'HIS',
                label: '历史账单'
                }, {
                value: 'NOW',
                label: '未支付'
                }],
                value: 'NOW',
        }
    },
    mounted() {
        this.getBill()
        this.getUserInfo()
    },
    methods: {
        getStatusText(status) {
            const statusMap = {
            'C': '已完成',
            'B': '待支付',
            'A': '未出账',
            'D': '已作废',
            'E': '已退租'
            };

            return statusMap[status] || ''; // 返回对应状态的文本，如果不存在则返回空字符串
        },
        toggleStatus() {
            this.pageNum = 1
            this.getBill()
        },
        // 获取账单
        getBill(){
            this.tableData = []
            let params = {
                   "userId" : JSON.parse(localStorage.getItem('userId')),
                    "orderStatus" : this.value,
                    "pageNum" : this.pageNum,
                    "pageSize" : this.pageRow
                    }
                    request.post('/operating-real-estate/user/mine-order-list',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                this.numContent = res.data.totalCount
                                this.tableData = res.data.list
                                console.log("1111"+this.tableData)
                            }
                        }
                    );
        },
        
        getUserInfo(){
           
            let params = {
                "userId" :  JSON.parse(localStorage.getItem('userId'))
            }
            console.log(params.userId)
            request.get('/operating-real-estate/user/getUserInfo',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                this.userInfo = res.data
                               this.phone = this.userInfo.phone.replace(/^(\d{3})\d{4}(\d{4})/,'$1****$2')
                            }
                        }
                    );
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageRow = val;
            this.getBill();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.getBill();
        }
    },
}
</script>
<style lang="less" scoped>
    .head-portrait-header{
        height: 174px;
        border-bottom: 1px solid #E7E7E7;
        display: flex;
        flex-direction: row;
    }
    .head-portrait{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin: 30px 0px 10px 40px;
    }
    .head-portrait-btn{
        padding: 0px;
        display: block;
        width: 60px;
        height: 24px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #3094FF;
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #3094FF;
        margin-left: 40px;
    }
    .head-portrait-header-left{
        flex: 2;
    }
    .head-portrait-header-right{
        flex: 8;
    }
    .portrait-header-right-first{
        margin: 40px 0px 26px 0px;
    }
    .my-bill-name{
        width: 48px;
        height: 24px;
        font-size: 24px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 24px;
        margin-right: 20px;
    }
    .my-bill-phone{
        width: 84px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }
    .portrait-header-right-second{
        margin-bottom: 20px;
    }
    .my-bill-sex-name{
        width: 28px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-right: 50px;
    }
    .my-bill-sex{
        width: 14px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }
    .real-name{
        width: 56px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-right: 22px;
    }
    .user-status{
        width: 42px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #63C465;
        line-height: 14px;
        margin-right: 22px;
    }
    .de-authentication{
        width: 42px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #3094FF;
        line-height: 14px;
    }



    .search-text{
        width: 70px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px; 
        margin-left: 20px;
    }
    .search{
        width: 200px;
        height: 34px;
        margin: 0px 10px 0px 20px;
    }
    .search-btn{
        width: 50px;
        height: 40px;
        background: #3094FF;
        border-radius: 4px;
        line-height: 40px;
        vertical-align: middle;
    }
    /* 分页区域 */
     .pagination-container {
        text-align: right;
        /* display: inline-block; */
        margin-top: 20px;
        margin-right: 30px;
    }
    
</style>